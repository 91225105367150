import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql} from "gatsby";

const links = [
  ["Airing's Blog", "https://ursb.me/", "2022/06/05"]
]

const Links = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={`${siteTitle}`}>
      <Seo title="友链" />

      <h1>友链</h1>

      <ul>
        {
          links.map(l => (
            <li key={l[1]}>
              <a
                target="_blank"
                rel="noreferrer"
                href={l[1]}>
                { l[0] }
              </a>
              {` `}
              <span style={{fontSize: 'var(--fontSize-0)'}}>({l[2]})</span>
            </li>
          ))
        }
      </ul>
    </Layout>
  )
}

export default Links

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
